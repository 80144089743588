import React, { useContext, useState } from "react";
import { Box, ResponsiveContext } from "grommet";
import { isMobileTabletResponsive } from "../../utils/utils";
import { observer } from "mobx-react";
import { useProfileStore } from "../../hooks/useProfileStore";
import { useUserStore } from "../../hooks/useUserStore";
import { Container } from "../../components/Container";
import { Section } from "../../components/Sections";
import { UserSection } from "../../components/Sections/UserSection";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";
import coverImg from "../../images/webp/bgCabinet.webp";
import { ActivitySection } from "../../components/Sections/ActivitySection";
import { useDataStore } from "../../hooks/useDataStore";
import { LicenseSection } from "../../components/Sections/LicenseSection";
import { MyEvents } from "../MyEvents";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { MyGoods } from "../MyGoods";

const Cabinet = observer(() => {
  const { type } = useParams();
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const [section, setSection] = useState("settings");

  const { logout } = useUserStore();
  const {
    changeEmailStatus,
    setNewMail,
    passwordMessage,
    setNewPassword,
    passwordErrorData,
    viewCodeParam,
    changePhoneStatus,
    setViewCodeField,
    setChangePhoneStatus,
    setNewPhone,
    sendNewPhoneCode,
    changeCodeStatusData,
    setChangeCodeStatus,
    setNewData,
    message,
    setStatus,
    setMessage,
    activities,
    contactStatus,
    setAdditionalNewData,
    getUserInfo,
    messageInfo,
    setMessageInfo,
    getUserLicenseInfo,
    userLicense,
    setUserLicense,
  } = useProfileStore();

  const { loadDeliveryRegions, regions } = useDataStore();

  useEffect(() => {
    if (type) setSection(type);
  }, [type]);

  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <Box
          flex
          direction={isMobileWidth ? "column" : "row"}
          justify="between"
          style={{ position: "relative" }}
        >
          <Box
            width={isMobileWidth ? "100%" : "25%"}
            margin={{ bottom: isMobileWidth && "2rem" }}
          >
            <Section
              setSection={setSection}
              isMobileWidth={isMobileWidth}
              section={section}
              logout={logout}
            />
          </Box>

          <Box
            width={isMobileWidth ? "100%" : "73%"}
            margin={{ bottom: isMobileWidth ? "3rem" : "0" }}
          >
            {section === "settings" && (
              <UserSection
                getDataMessage={message}
                setStatus={setStatus}
                setMessage={setMessage}
                setNewData={setNewData}
                getViewCodeParam={viewCodeParam}
                setChangeCodeStatus={setChangeCodeStatus}
                getChangeCodeStatus={changeCodeStatusData}
                sendNewPhoneCode={sendNewPhoneCode}
                setNewPhone={setNewPhone}
                changePhoneStatus={changePhoneStatus}
                setViewCodeField={setViewCodeField}
                setChangePhoneStatus={setChangePhoneStatus}
                getPasswordMessage={passwordMessage}
                setNewPassword={setNewPassword}
                getPasswordError={passwordErrorData}
                getChangeEmailStatus={changeEmailStatus}
                setNewMail={setNewMail}
                contactStatus={contactStatus}
                loadDeliveryRegions={loadDeliveryRegions}
                regions={regions}
                setAdditionalNewData={setAdditionalNewData}
                getUserInfo={getUserInfo}
                messageInfo={messageInfo}
                setMessageInfo={setMessageInfo}
              />
            )}
            {section === "activity" && (
              <ActivitySection activities={activities} />
            )}
            {section === "license" && (
              <LicenseSection
                getUserLicenseInfo={getUserLicenseInfo}
                userLicense={userLicense}
                setUserLicense={setUserLicense}
              />
            )}
            {section === "events" && <MyEvents />}
            {section === "shop" && <MyGoods />}
          </Box>
        </Box>
      </Container>
    </>
  );
});

export default Cabinet;
