import * as yup from "yup";
import { REGEX_MAIL } from "../../../const/regex";

export function makeValidationSchema(isDeliveryZero, isDelivery) {
  return yup.object().shape({
    email: yup
      .string()
      .required()
      .test("emailIslValid", "Введите валидный email", (value) => {
        const testValue = REGEX_MAIL.test(value);

        if (!testValue) return false;
        if (testValue) return true;
      }),
    phone: yup
      .string()
      .test("isValidPhone", "Введите корректный номер телефона", (value) => {
        if (!value || value.length !== 12) return false;
        if (value && value.length === 12) return true;
      }),
    relative_phone:
      isDeliveryZero &&
      yup
        .string()
        .test("isValidPhone", "Введите корректный номер телефона", (value) => {
          if (!value || value.length !== 12) return false;
          if (value && value.length === 12) return true;
        }),
    relative_name: isDeliveryZero && yup.string().required(),
    p_address: yup.string().required(),
    passport_code: yup.string().required(),
    passport_birth_place: yup.string().required(),
    passport_date: yup.string().required(),
    passport_source: yup.string().required(),
    delivery_method:
      !isDelivery &&
      yup
        .string()
        .required()
        .test("isMethod", "Заполните поле!", (value) => {
          if (value && value !== "Выберите способ доставки") return true;
          if (!value || value === "Выберите способ доставки") return false;
        }),
    passport: yup.string().required(),
    city: yup.string().required(),
    b_date: yup.string().required(),
    start_date: yup.string().required(),
    fio_en: yup.string().required(),
    fio_ru: yup.string().required(),
    file: yup.mixed().test("isFile", "Выберите файл!", (value) => {
      if (!value) return false;
      else if (value && !value[0]) return false;
      return true;
    }),
  });
}
