export const getPath = (obj) => {
  switch (obj.selector) {
    case `shop`:
      return `/api/promocode/shop/check/${obj.code}/${obj.public_id}`;
    case `license`:
      return `/api/promocode/license/check/${obj.code}/${obj.public_id}`;
    default:
      return `/api/promocode/check/${obj.code}/${obj.format_public_id}`;
  }
};
