import { Box, Heading } from "grommet";
import React, { useEffect } from "react";
import Loader from "react-js-loader";
import styled from "styled-components";
import { IconSvg } from "../../IconSvg";
import { LicenseInfoCard } from "./LicenseInfoCard";

const StyledSummary = styled.summary`
  position: relative;
  color: black;
  cursor: pointer;
  margin-bottom: 3rem;

  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  list-style: none;
`;

export const LicenseSection = ({
  getUserLicenseInfo,
  userLicense,
  setUserLicense,
}) => {
  useEffect(() => {
    if (!userLicense) {
      getUserLicenseInfo();
    }
    return () => setUserLicense(null);
    //eslint-disable-next-line
  }, []);

  const currentLicense = userLicense?.[0];
  const previousLicenses = userLicense?.slice(1);

  if (!userLicense) return <Loader type="spinner-default" bgColor="#DB4C4C" />;
  return (
    <>
      <Heading level={6} margin={{ bottom: "3rem" }} color="black">
        Лицензия
      </Heading>
      {!currentLicense ? (
        <Heading level={5} margin={{ bottom: "3rem" }}>
          Нет данных
        </Heading>
      ) : (
        <LicenseInfoCard license={currentLicense} />
      )}

      <details>
        <StyledSummary>
          Предыдущие лицензии
          <span>
            {" "}
            <IconSvg src="rectClose" width="12px" />
          </span>
        </StyledSummary>
        {previousLicenses.length ? (
          previousLicenses.map((el) => (
            <Box key={el.public_id} margin={{ top: "3rem" }}>
              <LicenseInfoCard license={el} />
            </Box>
          ))
        ) : (
          <h3
            style={{
              textAlign: "center",
              color: "black",
              marginTop: "3rem",
              marginBottom: "3rem",
            }}
          >
            Нет лицензий
          </h3>
        )}
      </details>
    </>
  );
};
