import Auth from "../pages/Auth";
import { SignIn } from "../pages/Auth/SignIn";
import Cabinet from "../pages/Cabinet";
import { AUTH, NO_AUTH } from "./user";

export const routes = {
  main: {
    path: "/",
    name: "Главная",
  },
  judgment: {
    path: "/judgment",
    name: "Судейский корпус",
  },
  notsupport: {
    path: "/notsupport",
    name: "notsupport",
  },
  shop: {
    path: "/shop",
    name: "Магазин",
  },
  add: {
    path: "/add",
    name: "Добавить",
  },
  signIn: {
    path: "/signin",
    name: "Авторизация",
  },
  me: {
    path: "/me",
    name: "Личный кабинет",
  },
  myEvents: {
    path: "/myevents",
    name: "Мои мероприятия",
  },
  basket: {
    path: "/basket",
    name: "Корзина",
  },
  auth: {
    path: "/signup",
    name: "Регистрация",
  },
  news: {
    path: "/news",
    name: "Новости",
  },
  partners: {
    path: "/partners",
    name: "Партнеры",
  },
  management: {
    path: "/management",
    name: "Руководство",
  },
  calendar: {
    path: "/calendarPage",
    name: "Календарь мероприятий",
  },
  calendarall: {
    path: "/calendarPage/all",
    name: "Все мероприятия",
  },
  calendarekp: {
    path: "/calendarPage/ekp",
    name: "Единый календарный план",
  },
  calendarru: {
    path: "/calendarPage/ru",
    name: "Любительские старты",
  },
  calendarchild: {
    path: "/calendarPage/child",
    name: "Лига Триатлона Дети",
  },
  calendarchildstart: {
    path: "/calendarPage/childstart",
    name: "Детские старты",
  },
  calendarseries4: {
    path: "/calendarPage/series4",
    name: "Кубок 4 колец",
  },
  // calendarsups: {
  //   path: "/calendarPage/sups",
  //   name: "Суперсерия",
  // },
  calendarregions: {
    path: "/calendarPage/regions",
    name: "Региональные соревнования",
  },
  historyTria: {
    path: "/historyTria",
    name: "История триатлона",
  },
  whereToStart: {
    path: "/whereToStart",
    name: "С чего начать?",
  },
  pages: {
    path: "/pages",
    name: "",
  },
  disciplines: {
    path: "/disciplines",
    name: "Дисциплины",
  },
  whatIsTriathlon: {
    path: "/WhatIsTriathlon",
    name: "Что такое триатлон?",
  },
  members: {
    path: "/members/group/:publicId",
    name: "Сборная",
  },
  olympicReserve: {
    path: "/olympic-reserve",
    name: "Олимпийский резерв",
  },
  // coachingStaff: {
  //   path: "/coaching-staff",
  //   name: "Тренерский состав",
  // },
  protocols: {
    path: "/protocols",
    name: "Протоколы",
  },
  regions: {
    path: "/regions",
    name: "Региональные федерации",
  },
  documents: {
    path: "/documents",
    name: "Документы",
  },
  selectionCriterias: {
    path: "/selection-criterias",
    name: "Критерии отбора",
  },
  archive: {
    path: "/archive",
    name: "Архив документов ФТР",
  },
  ratingsAthletes: {
    path: "/ratings-athletes",
    name: "Рейтинги спортсменов",
  },
  contacts: {
    path: "/contacts",
    name: "Контакты",
  },
  childrensTriathlon: {
    path: "/childrens-triathlon",
    name: "Детский триатлон",
  },
  amateurTriathlon: {
    path: "/amateur-triathlon",
    name: "Любительский триатлон",
  },
  electionResultsAnnual: {
    path: "/election-results-annual",
    name: "Генеральный секретарь ФТР Галина Шиповалова переизбрана в состав Исполкома Europe Triathlon",
  },
  ftrFvsrCollaboration: {
    path: "/ftr-fvsr-collaboration",
    name: "Президент ФТР Ксения Шойгу и президент ФВСР Вячеслав Екимов подписали меморандум о сотрудничестве",
  },
  indoorTriathlonJunior: {
    path: "/indoor-triathlon-junior",
    name: "Детский индор-триатлон пройдет в Crocus Fitness 13 марта",
  },
  championatTuymenskoyOblasti: {
    path: "/championat-tuymenskoy-oblasti",
    name: "Чемпионат Тюменской области по зимнему триатлону состоится 13 марта",
  },
  championatKemerovo2021: {
    path: "/championat-kemerovo-2021",
    name: "Чемпионат России по триатлону состоится в Кемеровской области в июле 2021 года",
  },
  firstMeetingParatriathlon: {
    path: "/first-meeting-paratriathlon",
    name: "Президент Федерации триатлона Ксения Шойгу провела первую встречу комиссии по паратриатлону",
  },
  news10032100: {
    path: "/news10032100",
    name: "Президент Федерации триатлона России Ксения Шойгу представила план развития ФТР на 2021 год",
  },
  news10032101: {
    path: "/news10032101",
    name: "Обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»",
  },
  news150321: {
    path: "/news150321",
    name: "Ровно 100 дней назад обновленный состав президиума Федерации триатлона России начал свою работу",
  },
  news220321: {
    path: "/news220321",
    name: "Сборная России по зимнему триатлону завоевала больше всех медалей на Чемпионате мира в Андорре",
  },
  news280321: {
    path: "/news280321",
    name: "Финал Кубка России по зимнему триатлону и соревнования среди любителей TRI59",
  },
  news070421: {
    path: "/news070421",
    name: "Окончен набор слушателей на обучение «Тренер в избранном виде спорта. Триатлон»",
  },
  news190421: {
    path: "/news190421",
    name: "Открытие отделения по направлению «Триатлон»",
  },
  news200421: {
    path: "/news200421",
    name: "Президиумом ФТР утверждены критерии и условия отбора кандидатов на международные соревнования в 2021 году",
  },
  antidoping: {
    path: "/antidoping",
    name: "Антидопинг",
  },
  superseries: {
    path: "/superseries",
    name: "Суперсерия ФТР 2021",
  },
  error: {
    path: "/404",
    name: "Ошибка",
  },
  success: {
    path: "/success",
    name: "Спасибо, ваша заявка принята!",
  },
  license: {
    path: "/license",
    name: "Лицензия",
  },
  results: {
    path: "/results",
    name: "Результаты",
  },
  amateurs: {
    path: "/amateurs",
    name: "Любители",
  },
  thanks: {
    path: "/thanks",
    name: "Спасибо за покупку",
  },
  basketThanks: {
    path: "basket/thanks",
    name: "Спасибо за покупку",
  },
  internationalTriathlon: {
    path: "/international-triathlon",
    name: "Международный триатлон",
  },
  resetPassword: {
    path: `/verify/resetpassword/:code`,
    name: `Сброс пароля`,
  },
  verifyEmail: {
    path: `/verify/email/:code`,
    name: `Подтверждение пароля`,
  },
  verifyNewEmail: {
    path: `/verify/new/email/:code`,
    name: `Подтверждение пароля`,
  },
  newPassword: {
    path: `/newpass`,
    name: `Создание нового пароля`,
  },
};

export const privateRoutes = [
  {
    id: routes.auth.name,
    path: routes.auth.path,
    status: NO_AUTH,
    pathRedirect: `${routes.me.path}/license`,
    element: <Auth />,
  },
  {
    id: routes.signIn.name,
    path: routes.signIn.path,
    status: NO_AUTH,
    pathRedirect: `${routes.me.path}/license`,
    element: <SignIn />,
  },
  /* {
    id: routes.myEvents.name,
    path: routes.myEvents.path,
    status: AUTH,
    pathRedirect: routes.signIn.path,
    element: <Events />,
  }, */
  {
    id: routes.me.name,
    path: routes.me.path,
    status: AUTH,
    pathRedirect: routes.signIn.path,
    element: <Cabinet />,
  },
];

export const verifyRoutes = [
  {
    id: routes.verifyEmail.name,
    path: routes.verifyEmail.path,
    variants: "verifyEmail",
  },
  {
    id: routes.verifyNewEmail.name,
    path: routes.verifyNewEmail.path,
    variants: "verifyNewEmail",
  },
  {
    id: routes.resetPassword.name,
    path: routes.resetPassword.path,
    variants: "checkResetCode",
  },
];
