import { makeAutoObservable, observable, toJS } from "mobx";
import { backendLogger } from "../utils/logger";
import { returnAuthConfig } from "../utils/config";

export class DataStore {
  news = [];
  filteredEventsData = [];
  formatsData = [];
  eventTypeData = [];
  eventCitiesData = [];
  event = {};
  sizesData = [];
  regionsData = [];
  teamsData = [];
  eventsData = [];
  calendarList = [];
  oneNewsList = null;
  protocolsData = [];
  licenseData = [];
  partnersBanners = [];
  documentData = [];
  licenseDelType = [];
  pageContentData = [];
  licenseInfoData = "";
  sizesByIdData = [];
  eventGoodsByIdData = [];
  iTriathlonData = {};
  aTriathlonData = {};
  judgmentData = {};
  corpCupData = {};
  managementData = [];

  setLicense = (license) => {
    this.licenseData = license;
  };
  setPageContent = (pageContentData) => {
    this.pageContentData = pageContentData;
  };
  setLicenseDeliveryType = (licenseDelType) => {
    this.licenseDelType = licenseDelType;
  };
  setDocument = (document) => {
    this.documentData = document;
  };

  setPartnersBanners = (banner) => {
    this.partnersBanners = banner;
  };
  setEventGoodsById = (response) => {
    this.eventGoodsByIdData = [
      ...this.eventGoodsByIdData,
      {
        id: response.id,
        data: [...response.data],
      },
    ];
  };

  setLicenseInfo = (info) => {
    this.licenseInfoData = info;
  };
  setManagementData = (data) => {
    this.managementData = data;
  };

  setProtocols = (protocols) => {
    this.protocolsData = protocols;
  };
  setSizes = (sizes) => {
    this.sizesData = [{ size: "Футболка не выбрана", count: 0 }, ...sizes];
  };
  setSizesByID = (data) => {
    this.sizesByIdData = [
      ...this.sizesByIdData,
      {
        id: data.id,
        sizes: [
          { size: "Футболка не выбрана", count: "defaultValue" },
          ...data.sizes,
        ],
      },
    ];
  };
  clearSize = () => {
    this.sizesData = [];
    this.sizesByIdData = [];
  };
  setCalendarList = (list) => {
    this.calendarList = list;
  };
  setEvents = (events) => {
    this.eventsData = events;
  };
  setITriathlonData = (data) => {
    this.iTriathlonData = data;
  };
  setCorpCupData = (data) => {
    this.corpCupData = data;
  };
  setJudgmentData = (data) => {
    this.judgmentData = data;
  };
  setATriathlonData = (data) => {
    this.aTriathlonData = data;
  };
  setOneNews = (news) => {
    this.oneNewsList = news;
  };
  setNews = (news) => {
    this.news = news;
  };

  setType = (eventType) => {
    this.eventTypeData = eventType;
  };

  setEventCities = (eventCities) => {
    this.eventCitiesData = eventCities;
  };

  setTeams = (teams) => {
    this.teamsData = teams;
  };

  cleanFormats = (formats) => {
    this.event = {};
    this.formatsData = formats;
  };

  setLoadFormats = (formats) => {
    this.formatsData = formats;
  };

  setFilteredEvents = (filteredEvents) => {
    this.filteredEventsData = filteredEvents;
  };
  setRegions = (regions) => {
    this.regionsData = regions;
  };

  loadEvents = async () => {
    const response = await this.api.get(`/api/event/list`);

    if (response.status === 200 && response.data.values.length) {
      this.setEvents(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadITriathlonData = async () => {
    const response = await this.api.get(`api/page/international`);

    if (response.status === 200) {
      this.setITriathlonData(response.data);
    } else if (response.status >= 400) backendLogger(response);
  };
  loadATriathlonData = async () => {
    const response = await this.api.get(`api/page/amateurtriathlon`);

    if (response.status === 200) {
      this.setATriathlonData(response.data);
    } else if (response.status >= 400) backendLogger(response);
  };
  loadCorpCup = async () => {
    const response = await this.api.get(`api/page/corpcup`);

    if (response.status === 200) {
      this.setCorpCupData(response.data);
    } else if (response.status >= 400) backendLogger(response);
  };
  loadJudgmentData = async () => {
    const response = await this.api.get(`/api/page/judgment`);

    if (response.status === 200) {
      this.setJudgmentData(response.data);
    } else if (response.status >= 400) backendLogger(response);
  };
  loadPageContent = async (id) => {
    const response = await this.api.get(`/api/page/${id}`);

    if (response.status === 200 && response.data) {
      this.setPageContent(response.data);
    } else if (response.status >= 400) backendLogger(response);
  };
  loadLicenseDeliveryType = async () => {
    const response = await this.api.get(`/api/delivery/license`);

    if (response.status === 200 && response.data.values.length) {
      this.setLicenseDeliveryType(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadLicense = async () => {
    const response = await this.api.get(`/api/licenses`);

    if (response.status === 200 && response.data.values.length) {
      this.setLicense(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadEventGoods = async (id) => {
    const response = await this.api.get(`/api/shop/related/${id}`);

    if (response.status === 200) {
      this.setEventGoodsById({ data: response.data.values, id });
    }
  };
  loadDocument = async ({ id }) => {
    const response = await this.api.get(`api/files/section/${id}`);
    if (response.status === 200 && response.data.values.length) {
      this.setDocument(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };
  loadCalendarList = async () => {
    const response = await this.api.get(
      this.additionalApi + `/api/calendar/list`
    );

    if (response.status === 200) {
      this.setCalendarList(response.data);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadFormatsByFormatId = async (format_id) => {
    const response = await this.api.get(`/api/event_format/${format_id}`);
    if (response.status === 200) {
      this.setLoadFormats(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  loadEventsVolunteer = async () => {
    const config = returnAuthConfig();
    const response = await this.api.get(`/api/event/list`, config);

    if (response.status === 200 && response.data.values.length) {
      this.setEvents(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadOneNews = async (public_id) => {
    const response = await this.api.get(`/api/news/one/${public_id}`);

    if (response.status === 200) {
      this.setOneNews(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };
  getAllProtocols = async () => {
    try {
      // const { data } = await this.api.get("/api/files/section/protocol");
      const { data } = await this.api.get("/server/document/type/protocol");
      this.setProtocols(data);
    } catch (error) {
      console.log(error);
    }
  };
  loadNews = async () => {
    const response = await this.api.get("/api/news");

    if (response.status === 200) {
      this.setNews(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };
  loadNewsPreview = async (limit) => {
    const response = limit
      ? await this.api.get(`/api/news/preview?limit=${limit}`)
      : await this.api.get(`/api/news/preview`);
    if (response.status === 200) {
      this.setNews(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  loadSize = async (data) => {
    const response = await this.api.get(
      `/api/shop/shirts/${data.event_city_public_id}`
    );
    if (response.data.values.length > 0) {
      if (response.status === 200) this.setSizes(response.data.values);
    }
  };

  loadSizeById = async (data) => {
    const response = await this.api.get(
      `/api/shop/shirts/${data.event_city_public_id}`
    );

    if (response.status === 200)
      if (response.data.values.length > 0) {
        this.setSizesByID({
          sizes: response.data.values,
          id: data.id,
        });
      }
  };

  getPartnersBanners = async () => {
    const response = await this.api.get("/api/page/banner/partners");

    if (response.status === 200) {
      this.setPartnersBanners(response.data.values);
    }
  };

  getManagementData = async () => {
    const response = await this.api.get("/api/managements");

    if (response.status === 200) {
      this.setManagementData(response.data.values);
    }
  };

  getLicenseInfo = async () => {
    const response = await this.api.get("/api/page/licenseinfo");

    if (response.status === 200) {
      this.setLicenseInfo(response.data?.text);
    }
  };

  loadFormats = async (event_id, city_id, hidden = false) => {
    const response = await this.api.get(
      `/api/event_format/event/${event_id}/${city_id}${
        hidden ? "?hidden=true" : ""
      }`
    );

    if (response.status === 200) {
      this.setLoadFormats(response.data.values);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };
  loadFilteredEvents = async (data) => {
    const response = await this.api.post(`/api/event_city/filtered`, data);

    if (response.status === 200) {
      this.setFilteredEvents(response.data.values);
    }
  };

  loadEventType = async () => {
    const response = await this.api.get(`api/event_type/list`);

    if (response.status === 200) this.setType(response.data.values);
  };

  loadEventCities = async () => {
    const response = await this.api.get(`api/event/cities`);

    if (response.status === 200) this.setEventCities(response.data.values);
  };

  loadTeams = async (format_public_id) => {
    const response = await this.api.get(`/api/teams/${format_public_id}`);

    if (response.status === 200) this.setTeams(response.data.values);
    else this.setTeams([]);
  };

  loadDeliveryRegions = async () => {
    const response = await this.api.get(`/api/location/region/list/RU`);
    if (response.status === 200) {
      this.setRegions(response.data.values);
    }
  };

  get newsList() {
    return this.news;
  }
  getNewsByID = (id) => {
    return this.newsList.find((item) => item.public_id === id);
  };
  getNewsWithoutID = (id) => {
    return this.newsList.filter((item) => item.public_id !== id);
  };

  getBanners = (type) => {
    return this.partnersBanners.filter((el) => el.banner_type === type);
  };
  getSizesByID = (id) => {
    return this.sizesByIdData.find((el) => el.id === id);
  };
  getEventGoodsById = (id) => {
    return this.eventGoodsByIdData?.find((el) => el?.id == id);
  };
  get filteredEvents() {
    return this.filteredEventsData;
  }

  get calendarEvent() {
    return this.calendarList;
  }

  get events() {
    return this.eventsData;
  }

  get eventType() {
    return this.eventTypeData;
  }
  get eventCities() {
    return this.eventCitiesData;
  }
  get pageContent() {
    return this.pageContentData;
  }
  get teams() {
    return this.teamsData;
  }
  get regions() {
    return this.regionsData;
  }
  get licensesList() {
    return this.licenseData;
  }
  get documents() {
    return this.documentData;
  }
  get sizes() {
    return this.sizesData;
  }
  get oneNews() {
    return this.oneNewsList;
  }
  get protocols() {
    return this.protocolsData;
  }
  get licenseDeliveryType() {
    return this.licenseDelType;
  }
  get formats() {
    return this.formatsData;
  }
  get licenseInfo() {
    return this.licenseInfoData;
  }
  get internationalTriathlon() {
    return this.iTriathlonData;
  }
  get amateurTriathlon() {
    return this.aTriathlonData;
  }
  get judgment() {
    return this.judgmentData;
  }
  get corpcup() {
    return this.corpCupData;
  }
  get management() {
    return this.managementData;
  }

  constructor(api, additionalApi) {
    this.api = api;
    this.additionalApi = additionalApi;

    makeAutoObservable(this, {
      api: observable.ref,
      additionalApi: observable.ref,
    });
  }
}
